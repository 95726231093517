<template>
    <div class="content-wrapper">
        <validation-observer
            v-slot="{ handleSubmit }" ref="VForm"
        >
            <b-form @submit.prevent="handleSubmit(doSubmitCRUD('VForm', $refs))">
                <div class="content pt-0">
                    <div class="card mb-1">
                    </div>
                    <template>
                        <div class="card mb-1">
                            <div class="card-body bg_card_blue">
                                <div class="row">
                                <div class="col-md-9">
                                    <table class="table table-borderless table-sm text-uppercase">
                                    <tbody>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No. Rekam Medis</h4>
                                            <p>{{rowPasien.ap_code||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama Pasien</h4>
                                            <p>{{rowPasien.ap_fullname||"-"}}</p>
                                            </div>
                                        </td>
                                        
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nomor Registrasi</h4>
                                            <p>{{row.ar_reg_code||"-"}} - {{row.ar_reg_date | moment("DD MMMM YYYY")}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tempat, Tanggal Lahir </h4>
                                            <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Usia</h4>
                                            <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Jenis Kelamin</h4>
                                            <p>{{rowPasien.cg_label||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card">
                            <template>
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Data Rencana Kontrol</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-4">
                                            <label>Nama Dokter<small class="txt_mandatory">*</small></label>
                                            
                                            <template v-if="row.arrk_is_created_sk != 'Y'">
                                                <v-select placeholder="Pilih Dokter" v-model="row.arrk_bu_id"
                                                :options="mDokter" label="text" :clearable="true"
                                                :reduce="v=>v.value"></v-select>
                                                <VValidate :name="'Nama Dokter'" v-model="row.arrk_bu_id" :rules="{required:1}" />
                                            </template>
                                            
                                            <b-form-input v-else type="text" disabled v-model="row.bu_full_name"
                                            class="form-control" placeholder="Nama Dokter" />
                                            
                                        </div>
                                        
                                        <div class="col-md-4">
                                            <label>Nama Poli<small class="txt_mandatory">*</small></label>
                                            <b-form-input type="text" disabled v-model="row.mpo_name"
                                            class="form-control" placeholder="Nama Poli" />
                                        </div>
                                        
                                        <div class="col-md-4">
                                            <label>Tanggal Kontrol<small class="txt_mandatory">*</small></label>
                                            <div class="input-group">
                                                <div class="input-group mb-3">
                                                    <datepicker :disabledDates="disabledDates"
                                                    input-class="form-control transparent"
                                                    placeholder="Tanggal Pemeriksaan" class="my-datepicker"
                                                    calendar-class="my-datepicker_calendar"
                                                    v-model="row.arrk_tanggal_kontrol">
                                                    </datepicker>
                                                    <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i
                                                        class="icon-calendar"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="row.arrk_no_sk">
                                            <label>No SKDP<small class="txt_mandatory">*</small></label>
                                            <b-form-input type="text" disabled v-model="row.arrk_no_sk"
                                            class="form-control" placeholder="No SKDP" />
                                        </div>

                                    </div>

                                    <div class="form-row mt-4">
                                        <div class="col-md-4">
                                            <label>Catatan Kontrol</label>
                                            <p>{{dataRM.atld_catatan_kontrol||"-"}}</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </template>

                            <div class="card-footer">
                                <div class="text-right">
                                <button @click="$router.back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                               
                                <!--
                                <button type="button" @click="addSEP()" v-if="row.arrk_is_created_sk == 'Y' && row.arres_jaminan == 3" class="btn btn-primary ml-2 mr-2">Buat SEP <i class="icon-add"></i></button>
                                -->

                                <button v-if="row.arrk_is_created_sk != 'Y'" type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                               
                                </div>
                            </div>
                        </div>
                    </template>
                    <!-- /dashboard content -->
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>


<script>
    import GlobalVue from '@/libs/Global.vue'
    import Gen from '@/libs/Gen.js'
    const moment = require('moment')
    import Datepicker from 'vuejs-datepicker'
    import _ from 'lodash'
    import $ from 'jquery'
    
    export default {
        extends: GlobalVue,    
        components: {
            Datepicker
        }, 
        data(){
            return {
                rowPasien:{},
                dataRM: {},
                mHariLibur: [],
                mDokter: []
            }
        },
        computed: {
            isAdd(){ return true},
            disabledDates() {
                let datesDisabled = []
                for (let i = 0; i < this.mHariLibur.length; i++) {
                    datesDisabled.push(new Date(moment(this.mHariLibur[i]['mhl_date'])))
                }
                return {
                to: new Date(moment().format('YYYY-MM-DD')),
                dates: datesDisabled,
                days: [0],

                }
            },
        },
        methods: {   
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            ceilData(value) {
                return Math.ceil(value)
            },
            toValidate(val){
                return {...val}
            },
            resetData(){
                this.row.arrk_ap_id = null
            },
            doSubmitCRUD(IDForm='VForm', refs=false, typeSubmit=false, params={}){
                return this.doSubmit(
                    "/do/"+this.modulePage, 
                    _.assign(
                        {type:'update'}, 
                        {id: (this.pageId||this.pageSlug)}, 
                        _.clone(this.row)
                    ), 
                    (type, resp)=>{
                        if(type=='success'){
                            this.$router.push({name:this.$route.name, params:params }).catch(()=>{})
                        }else{
                            if(resp.response.status==422) {
                                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                                var msg = ""
                                $.each(Object.keys(resp.response.data),(i,v)=>{
                                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                                })
                                return this.$swal("Blocked With Validation!", msg)
                                }else{
                                return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                                }
                            }else if(resp.response.status==400){
                                return this.$swal({
                                title: resp.response.data.title||resp.response.data.message,
                                html: resp.response.data.title?resp.response.data.message:"",
                                icon: 'error',
                                confirmButtonText: 'Ok'
                                })
                            }
                        }
                    },
                    'POST', IDForm, refs
                )
            },
            addSEP(){
                this.loadingOverlay = true
                this.row.ap_code = this.rowPasien.ap_code
                this.row.ap_phone_number = this.rowPasien.ap_phone_number
                Gen.apiRest('/do/' + this.modulePage, {
                    data: {
                        ...this.row,
                        type: 'add-new-sep',
                    }
                }, 'POST').then(res => {
                    let resp = res.data
                    this.loadingOverlay = false
                    return this.$swal({
                        title: resp.message,
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        this.$router.push({name : 'BPJSDataSEP', params: {'pageSlug':resp.data.id}}).catch(()=>{})
                    })
                })
                .catch(err=>{
                    this.loadingOverlay = false
                    return this.$swal({
                        icon: 'error',
                        text: err.response?.data?.message || "Data tidak dapat diinput"
                    })
                })
            },
            addSEPLink(){
                this.$router.push({ name: 'BPJSDataSEP', params: {pageSlug: 'add'}, query: {reg_id: this.row.arrk_ar_id, pasien_id: this.row.arrk_ap_id}}).catch(()=>{})
            }
        },
        mounted() {
            this.apiGet()
        },
    }
    
</script>